import axios from 'axios';
import * as TYPES from '../types.js'
import Vue from 'vue'
import router from '@/config/AppRoutes'


const AuthUserModule = {
  state: {
    user: null,
    isBusy: false,
    error: null,
    apiVersion: ''
  },
  getters: {
    [TYPES.AUTH_USER_GET_DATA] (state) {
      return state.user
    }
  },
  mutations: {
    [TYPES.AUTH_USER_SET_DATA] (state, value) {
      state.user = value;
      state.isBusy = false
      state.error = null
    },
    [TYPES.AUTH_USER_SET_BUSY] (state, value) {
      state.isBusy = value
    },
    [TYPES.AUTH_USER_SET_ERROR] (state, value) {
      state.isBusy = false
      state.error = value
    }
  },
  actions: {
    [TYPES.AUTH_USER_LOAD] ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.isBusy) {
          commit(TYPES.AUTH_USER_SET_BUSY, true)
          axios.get('/authenticate/user')
            .then(response => {
              console.log('Response', response);
              commit(TYPES.AUTH_USER_SET_DATA, response.data)
              resolve(response.data)
            })
            .catch(error => {
              console.log('Response error', error);
              commit(TYPES.AUTH_USER_SET_ERROR, error)
              reject(error)
            })
        } else {
          reject('busy')
        }
      });
    },
    [TYPES.AUTH_USER_SAVE] ({ commit, state }, userData) {
      return new Promise((resolve, reject) => {
        if (!state.isBusy) {
          commit(TYPES.AUTH_USER_SET_BUSY, true)
          axios.post('/authenticate/user', userData)
            .then(response => {
              console.log('Response', response);
              commit(TYPES.AUTH_USER_SET_DATA, response.data)
              Vue.notify({
                group: 'main',
                type: 'success',
                title: 'Twoje dane',
                text: 'Zapis danych zakończony sukcesem'
              });
              resolve(response.data)
            })
            .catch(error => {
              console.log('Response error', error);
              commit(TYPES.AUTH_USER_SET_ERROR, error)
              reject(error)
            })
        } else {
          reject('busy')
        }
      });
    },
    [TYPES.AUTH_USER_SET_NEW_PASSWORD] ({ commit, state }, {password, redirect}) {
      return new Promise((resolve, reject) => {
        if (!state.isBusy) {
          commit(TYPES.AUTH_USER_SET_BUSY, true)
          axios.post('/authenticate/password', {password})
            .then(response => {
              console.log('Response', response);
              commit(TYPES.AUTH_USER_SET_DATA, response.data)
              Vue.notify({
                group: 'main',
                type: 'success',
                title: 'Zmiana hasła',
                text: ' Zmiana hasła zakończona sukcesem'
              });
              redirect ? router.push(redirect) : router.push('/')
              resolve(response.data.user)
            })
            .catch(error => {
              console.log('Response error', error);
              commit(TYPES.AUTH_USER_SET_ERROR, error)
              reject(error)
            })
        } else {
          reject('busy')
        }
      });
    },
    [TYPES.AUTH_USER_A_UPLOAD_AVATAR] ( {commit, state, rootState, dispatch}, {imageBlob}) {
      return new Promise( (resolve, reject) => {
          if (!state.isBusy) {
              commit(TYPES.AUTH_USER_SET_BUSY, true)
              var url = '/authenticate/upload_avatar'
              var data = new FormData()
              data.append('file', imageBlob);
              return axios.post(url, data).then(response => {
                  commit(TYPES.AUTH_USER_SET_DATA, response.data)
                  Vue.notify({
                      group: 'main',
                      type: 'success',
                      title: 'Edycja użytkownika',
                      text: 'Zapis danych zakończony sukcesem'
                  });
                  resolve(response.data)
              }).catch(error => {
                  commit(TYPES.SYSTEMUSERS_M_SET_ERROR, error)
                  reject(error)
              });
          } else {
              reject('busy')
          }
      });
    }
  }
}

export default AuthUserModule
