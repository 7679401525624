import axios from 'axios';
import * as TYPES from './types.js';
import Vue from 'vue'
/*
//copy to types.js

*/
export const makeDefaultVuexModule = (typesPrefix, urlBase, defaultOrderBy, defaultOrderDir='ASC') => {
    return {
        state() {
            return {
                items: [],
                allItems: [],
                total: 0,
                currentPage: 0,
                hasMore: true,
                order: {
                    by: defaultOrderBy,
                    dir: defaultOrderDir
                },
                selected: null,
                filters: {},

                isBusy: false,
                error: null,
                removeAllItems: false,
            }
        },
        getters: {
            [TYPES[typesPrefix + '_G_GET_ALL_ITEMS']](state) {
                return state.allItems;
            },
            [TYPES[typesPrefix + '_G_GET_ITEMS']](state) {
                return {
                    items: state.items,
                    total: state.total,
                    currentPage: state.currentPage,
                    hasMore: state.hasMore,
                    order: state.order,
                    filters: state.filters,
                }
            },
            [TYPES[typesPrefix + '_G_GET_SELECTED']](state) {
                return state.selected
            },
            [TYPES[typesPrefix + '_G_GET_IS_BUSY']](state) {
                return state.isBusy
            },
            [TYPES[typesPrefix + '_G_GET_ERROR']](state) {
                return state.error
            }
        },
        mutations: {
            [TYPES[typesPrefix + '_M_ADD_ALL_ITEMS']](state, payload) {
                state.allItems = payload
                state.error = null
                state.isBusy = false
            },
            [TYPES[typesPrefix + '_M_ADD_ITEMS']](state, payload) {
                if (state.removeAllItems) {
                    state.items = []
                    state.removeAllItems = false
                }
                state.items = [...state.items, ...payload.data]
                state.currentPage = payload.current_page
                state.hasMore = payload.current_page < payload.last_page
                state.total = payload.total
                state.error = null
                state.isBusy = false
            },
            [TYPES[typesPrefix + '_M_REPLACE_ITEM']](state, item) {

                state.items = state.items.map( (_item) => {
                    if (_item.id === item.id) return item; else return _item;
                })
                state.error = null
                state.isBusy = false
            },
            [TYPES[typesPrefix + '_M_SET_BUSY']](state, payload) {
                state.isBusy = payload && payload.isBusy === false ? false : true
                if (payload && payload.filters) state.filters = payload.filters
                if (payload && payload.order) state.order = payload.order
                state.removeAllItems = payload && payload.removeAllItems === true ? true : false
                if (payload && payload.removeAllItems === true) {
                    state.currentPage = 0
                    state.hasMore = true
                }
            },
            [TYPES[typesPrefix + '_M_SET_SELECTED']](state, payload) {
                state.selected = payload
                state.error = null
                state.isBusy = false
            },
            [TYPES[typesPrefix + '_M_SET_ERROR']](state, payload) {
                state.error = payload
                state.isBusy = false
            }
        },
        actions: {
            [TYPES[typesPrefix + '_A_LOAD']]({commit, state}, params) {
                return new Promise((resolve, reject) => {
                    if (!state.isBusy) {
                        commit(TYPES[typesPrefix + '_M_SET_BUSY'], {
                            filters: params && params.filters ? params.filters : null,
                            order: params && params.order ? params.order : null,
                            removeAllItems: params && params.more ? !params.more : true
                        })
                        axios.get(urlBase, {
                            params: {
                                page: state.currentPage + 1,
                                orderBy: state.order.by,
                                orderDir: state.order.dir,
                                filters: state.filters
                            }
                        })
                            .then(response => {
                                commit(TYPES[typesPrefix + '_M_ADD_ITEMS'], response.data)
                                resolve(response.data)
                            })
                            .catch(error => {
                                commit(TYPES[typesPrefix + '_M_SET_ERROR'], error)
                                reject(error)
                            })
                    } else {
                        reject('busy');
                    }
                })
            },

            [TYPES[typesPrefix + '_A_LOAD_ALL']]({commit, state}, params) {
                return new Promise((resolve, reject) => {
                    if (!state.isBusy && ((params && params.forceLoad) || state.allItems.length == 0)) {
                        commit(TYPES[typesPrefix + '_M_SET_BUSY'], {
                            filters: params && params.filters ? params.filters : null,
                            order: params && params.order ? params.order : null,
                        })
                        axios.get(urlBase + '_all', {
                            params: {
                                page: state.currentPage + 1,
                                orderBy: state.order.by,
                                orderDir: state.order.dir,
                                filters: state.filters
                            }
                        })
                            .then(response => {
                                commit(TYPES[typesPrefix + '_M_ADD_ALL_ITEMS'], response.data)
                                resolve(response.data)
                            })
                            .catch(error => {
                                commit(TYPES[typesPrefix + '_M_SET_ERROR'], error)
                                reject(error)
                            })
                    } else if (state.isBusy) {
                        reject('busy');
                    }
                })
            },

            [TYPES[typesPrefix + '_A_LOAD_SELECTED']]({commit, state}, id) {
                return new Promise((resolve, reject) => {
                    if (!state.isBusy) {
                        commit(TYPES[typesPrefix + '_M_SET_BUSY'])
                        axios.get(urlBase + '/' + id)
                            .then((response) => {
                                //api zwraca pusty objekt jako pusta tablice - zabezpieniczenie
                                commit(TYPES[typesPrefix + '_M_SET_SELECTED'], (response.data.length === 0 && response.data instanceof Array) ? {} : response.data);

                                resolve(response.data)
                            })
                            .catch((error) => {
                                commit(TYPES[typesPrefix + '_M_SET_ERROR'], error);
                                reject(error)
                            })
                    } else {
                        reject('busy')
                    }
                })
            },
            [TYPES[typesPrefix + '_A_SAVE_SELECTED']]({commit, state}, data) {
                return new Promise((resolve, reject) => {
                    if (!state.isBusy) {
                        commit(TYPES[typesPrefix + '_M_SET_BUSY'])
                        let httpMethod = 'post';
                        var url = urlBase
                        if (data.id > 0) {
                            httpMethod = 'post';
                            url += '/' + data.id;
                        } else if (data.get && data.get('id') > 0) { //jezeli data to FormData
                            httpMethod = 'post';
                            url += '/' + data.get('id');
                        }
                        return axios[httpMethod](url, data)
                            .then(response => {
                                commit(TYPES[typesPrefix + '_M_SET_SELECTED'], response.data)
                                Vue.notify({
                                    group: 'main',
                                    type: 'success',
                                    title: 'Zapis danych',
                                    text: 'Zapis danych zakończony sukcesem'
                                });
                                resolve(response.data)
                            }).catch(error => {
                                commit(TYPES[typesPrefix + '_M_SET_ERROR'], error)
                                reject(error)
                            })
                    } else {
                        reject('busy')
                    }
                });
            },
            [TYPES[typesPrefix + '_A_DELETE_SELECTED']]({commit, state}, userData) {
                return new Promise((resolve, reject) => {
                    if (!state.isBusy) {
                        commit(TYPES[typesPrefix + '_M_SET_BUSY'])
                        return axios.delete(urlBase + '/' + userData.id)
                            .then(response => {
                                commit(TYPES[typesPrefix + '_M_SET_SELECTED'], null)
                                Vue.notify({
                                    group: 'main',
                                    type: 'success',
                                    title: 'Usuwanie danych',
                                    text: 'Usunięcie danych zakończone sukcesem'
                                });
                                resolve(response.data)
                            }).catch(error => {
                                commit(TYPES[typesPrefix + '_M_SET_ERROR'], error)
                                reject(error)
                            })
                    } else {
                        reject('busy')
                    }
                });
            },
        },
    }
}



