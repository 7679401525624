import * as PRIVILEGES from '@/config/Privileges.js'

const Menu = [
    {
        name: 'Start',
        icon: 'fas fa-home',
        path: '/start',
    },
    {
        name: 'Import',
        icon: 'fas fa-cloud-download-alt',
        path: '/import',
    },
    {
        name: 'Rabaty',
        icon: 'fas fa-percent',
        children: [
            {
                name: 'Rabaty',
                path: '/discounts',
                icon: 'fa fa-percent'
            },
            {
                name: 'Hot Deal',
                path: '/discounts-hot-deal',
                icon: 'fa fa-percent'
            },
            {
                name: 'Max',
                path: '/discounts-max',
                icon: 'fa fa-percent'
            },
            {
                name: 'Promo',
                path: '/discounts-promo',
                icon: 'fa fa-percent'
            },
            {
                name: 'Repeater',
                path: '/discounts-repeater',
                icon: 'fa fa-percent'
            },
        ]
    },
    {
        name: 'Promocje',
        icon: 'fas fa-ad',
        path: '/yacht-promotions',
    },
    {
        name: 'Highlights',
        icon: 'fas fa-highlighter',
        path: '/yacht-highlights',
    },
    {
        name: 'Ankiety',
        icon: 'fas fa-check-to-slot',
        children: [
            {
                name: 'Lista',
                icon: 'fas fa-file-import',
                path: '/polls'
            },
            {
                name: 'Wczytaj CSV',
                icon: 'fas fa-file-import',
                path: '/polls/upload'
            }
        ],
    },
    {
        name: 'Logi',
        icon: 'fas fa-list-alt',
        path: '/logs',
    },
    {
        name: 'Użytkownicy',
        icon: 'fas fa-user',
        path: '/systemusers',
        permissionName: PRIVILEGES.PRIVILEGE_SYSTEMUSERS,
        permissionKind: PRIVILEGES.PRIVILEGE_KIND_VIEW,
    },
]

export default Menu