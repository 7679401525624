<template>
    <page>
        <h1 class="text-center">{{ $appSettings.appWelcomeMessage }}</h1>
    </page>
</template>
<script>
import Page from "@/pages/Common/Page";
export default {
    name: 'app',
    components: {Page},
    methods: {
        onClose() {
            console.log('panel closed');
        }
    }
}
</script>