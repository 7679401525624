<template>
	<div class="theme-panel" v-bind:class="{ 'active': expand}">
		<a href="javascript:;" v-on:click="toggleThemePanel" class="theme-collapse-btn"><i class="fa fa-cog"></i></a>
		<div class="theme-panel-content" data-scrollbar="true" data-height="100%">
			<h5>Ustawienia panelu</h5>
			
			<!-- BEGIN theme-list -->
			<div class="theme-list">
				<div class="theme-list-item" v-bind:class="{ 'active': theme === appTheme }" v-for="theme in themes" v-bind:key="theme">
					<a href="javascript:;" v-bind:class="'theme-list-link bg-' + theme" v-on:click="appTheme = theme">&nbsp;</a>
				</div>
			</div>
			<!-- END theme-list -->
			
			<div class="theme-panel-divider"></div>
			
			<div class="row mt-10px">
				<div class="col-8 control-label text-dark fw-bold">
					<div>Tryb Ciemny</div>
					<div class="lh-14">
						<small class="text-dark opacity-50">
                            Przystosuj wygląd aby zredukować świecenie i dać odpocząć oczom.
						</small>
					</div>
				</div>
				<div class="col-4 d-flex">
					<div class="form-check form-switch ms-auto mb-0">
						<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" id="appThemeDarkMode" v-model="appDarkMode" value="1" />
						<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
					</div>
				</div>
			</div>
			
			<div class="theme-panel-divider"></div>
			
			<!-- BEGIN theme-switch -->
			<div class="row mt-10px align-items-center">
				<div class="col-8 control-label text-dark fw-bold">Stały górny pasek</div>
				<div class="col-4 d-flex">
					<div class="form-check form-switch ms-auto mb-0">
						<input type="checkbox" class="form-check-input" name="app-header-fixed" id="appHeaderFixed" value="1" v-model="appHeaderFixed" />
						<label class="form-check-label" for="appHeaderFixed">&nbsp;</label>
					</div>
				</div>
			</div>
			<div class="row mt-10px align-items-center">
				<div class="col-8 control-label text-dark fw-bold">Ciemny górny pasek</div>
				<div class="col-4 d-flex">
					<div class="form-check form-switch ms-auto mb-0">
						<input type="checkbox" class="form-check-input" name="app-header-inverse" id="appHeaderInverse" value="1" v-model="appHeaderInverse" />
						<label class="form-check-label" for="appHeaderInverse">&nbsp;</label>
					</div>
				</div>
			</div>
			<div class="row mt-10px align-items-center">
				<div class="col-8 control-label text-dark fw-bold">Stały lewy panel</div>
				<div class="col-4 d-flex">
					<div class="form-check form-switch ms-auto mb-0">
						<input type="checkbox" class="form-check-input" name="app-sidebar-fixed" id="appSidebarFixed" value="1" v-model="appSidebarFixed" />
						<label class="form-check-label" for="appSidebarFixed">&nbsp;</label>
					</div>
				</div>
			</div>
			<div class="row mt-10px align-items-center">
				<div class="col-8 control-label text-dark fw-bold">Siatka lewego panelu</div>
				<div class="col-4 d-flex">
					<div class="form-check form-switch ms-auto mb-0">
						<input type="checkbox" class="form-check-input" name="app-sidebar-grid" id="appSidebarGrid" value="1" v-model="appSidebarGrid" />
						<label class="form-check-label" for="appSidebarGrid">&nbsp;</label>
					</div>
				</div>
			</div>
			<div class="row mt-10px align-items-center">
				<div class="col-md-8 control-label text-dark fw-bold">Gradient</div>
				<div class="col-md-4 d-flex">
					<div class="form-check form-switch ms-auto mb-0">
						<input type="checkbox" class="form-check-input" name="app-gradient-enabled" id="appGradientEnabled" value="1" v-model="appGradientEnabled" />
						<label class="form-check-label" for="appGradientEnabled">&nbsp;</label>
					</div>
				</div>
			</div>
			<!-- END theme-switch -->
			
			<div class="theme-panel-divider"></div>

		</div>
	</div>
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import {mapGetters, mapMutations} from "vuex";

export default {
	name: 'ThemePanel',
	data() {
		return {
			appOptions: AppOptions,
			expand: false,
			themes: ['red','pink','orange','yellow','lime','green','teal','cyan','blue','purple','indigo','dark']
		}
	},
    computed: {
        appDarkMode: {
            get () { return this.getAppOptions.appDarkMode},
            set (value) { this.changeAppOption({name: 'appDarkMode', value})}
        },
        appTheme: {
            get () { return this.getAppOptions.appTheme},
            set (value) { this.changeAppOption({name: 'appTheme', value})}
        },
        appSidebarFixed: {
            get () { return this.getAppOptions.appSidebarFixed},
            set (value) {
                if (!this.getAppOptions.appHeaderFixed && value) {
                    alert('Default Header with Fixed Sidebar option is not supported. Proceed with Fixed Header with Fixed Sidebar.');
                    this.appHeaderFixed = value;
                }
                this.changeAppOption({name: 'appSidebarFixed', value})
            }
        },
        appHeaderInverse: {
            get () { return this.getAppOptions.appHeaderInverse},
            set (value) { this.changeAppOption({name: 'appHeaderInverse', value})}
        },
        appSidebarGrid: {
            get () { return this.getAppOptions.appSidebarGrid},
            set (value) { this.changeAppOption({name: 'appSidebarGrid', value})}
        },
        appGradientEnabled: {
            get () { return this.getAppOptions.appGradientEnabled},
            set (value) { this.changeAppOption({name: 'appGradientEnabled', value})}
        },
        appHeaderFixed: {
            get () { return this.getAppOptions.appHeaderFixed},
            set (value) {
                if (this.getAppOptions.appSidebarFixed && !value) {
                    alert('Default Header with Fixed Sidebar option is not supported. Proceed with Default Header with Default Sidebar.');
                    this.appSidebarFixed = value;
                }
                this.changeAppOption({name: 'appHeaderFixed', value})
            }
        },
        ...mapGetters([
            'getAppOptions',
        ])
    },
	methods: {
        ...mapMutations([
            'changeAppOption'
        ]),
		toggleThemePanel: function() {
			this.expand = !this.expand;
		}
	}
}
</script>
