import store from '../store/index'
import moment from 'moment'

const appSettings = {
  getApiVersion() {
    return store.state.auth ? store.state.auth.api_version : '';
  },
  panelVersion: process.env.VUE_APP_PANEL_VERSION,
  appName: process.env.VUE_APP_NAME,
  appProducer: process.env.VUE_APP_PRODUCER,
  appWelcomeMessage: process.env.VUE_APP_WELCOME,
  copyrightsInfo: '',
  getCopyrightsYear() {
    return moment().format('YYYY')
  }
}

export default {
  install(Vue, options) {
    Vue.appSettings = appSettings
    Vue.prototype.$appSettings = appSettings
  }
}
