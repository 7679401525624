<template>
    <!-- BEGIN login -->
    <div class="login login-with-news-feed">
        <!-- BEGIN news-feed -->
        <div class="news-feed">
            <div class="news-image text-center" style="background-image: url(/assets/img/login-bg/login-bg-11.jpg); padding-top:40%;">
<!--                <img style="width:50%" src="/assets/img/logo-big.png">-->
            </div>
            <div class="news-caption">
                <h4 class="caption-title"><b>{{ $appSettings.appName }}</b> Admin</h4>
                <p>
                    {{ $appSettings.appWelcomeMessage }}
                </p>
            </div>
        </div>
        <!-- END news-feed -->

        <!-- BEGIN login-container -->
        <div class="login-container">
            <!-- BEGIN login-header -->
            <div class="login-header mb-30px">
                <div class="brand">
                    <div class="d-flex align-items-center">
                        <img class="logo-punt" src="/assets/img/logo-punt-single.png">
                        <b>{{ $appSettings.appName }}</b> Admin
                    </div>
                </div>
                <div class="icon">
                    <i class="fa fa-sign-in-alt"></i>
                </div>
            </div>
            <!-- END login-header -->

            <!-- BEGIN login-content -->
            <div class="login-content">

                <ValidationObserver v-slot="{ handleSubmit }">
                    <input-field type="text" label="Email" name="Email" v-model="login.email" rules="required|email" ></input-field>
                    <input-field type="password" label="Hasło" name="Hasło" v-model="login.password" rules="required" ></input-field>

                    <div v-if="login_error !== ''" class="pb-25px text-danger">
                      {{ login_error }}
                    </div>

                    <div class="mb-15px">
                        <button type="button" class="btn btn-success d-block h-45px w-100 btn-lg fs-14px" @click="handleSubmit(onSubmit)" :disabled="isBusy">Zaloguj się <span v-show="isBusy" class="fa fa-spin fa-spinner"></span></button>
                    </div>
                </ValidationObserver>
                <!--					<div class="mb-40px pb-40px text-dark">-->
                <!--						Not a member yet? Click <router-link to="/user/register-v3" class="text-success">here</router-link> to register.-->
                <!--					</div>-->
                <hr class="bg-gray-600 opacity-2" />
                <div class="text-gray-600 text-center text-gray-500-darker mb-0">
                    &copy; {{ $appSettings.appProducer }} All Right Reserved {{ moment().format('YYYY') }}
                </div>

            </div>
            <!-- END login-content -->
        </div>
        <!-- END login-container -->
    </div>
    <!-- END login -->
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import InputField from "@/components/Forms/InputField";
export default {
    components: {
        InputField
    },
    data() {
        return {
            moment,
            login: {
                email: '',
                password: '',
            },
            isBusy: false,
            login_error: ''
        }
    },
    created() {
        console.log('is busy what???', this.$store.state)
        AppOptions.appEmpty = true;
        this.$store.dispatch('logout');
    },
    beforeRouteLeave (to, from, next) {
        AppOptions.appEmpty = false;
        next();
    },
    methods: {
        onSubmit() {
            this.login_error = '';
            this.isBusy = true;
            this.$store.dispatch('login', {
                username: this.login.email,
                password: this.login.password,
                redirect: this.$route.query.redirect
            }).finally(() => {
                this.isBusy = false;
            }).catch((error) => {
              if (error.response.data.error === 'invalid_grant') {
                this.login_error = 'Błędne dane logowania';
              }
            })
        },
    },
}
</script>