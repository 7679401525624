import store from '@/store/index'

const privileges = {
    loggedUserHasRight (privilegeName, privilegeKind, forceCheck) {
        const user = store.state.authUser.user

        return this.userHasRight(user, privilegeName, privilegeKind, forceCheck);
    },
    userHasRight (user, privilegeName, privilegeKind, forceCheck) {
        const privileges = user.user_privileges;
        if (!forceCheck && user.is_admin === true) {
            return true;
        }
        if(!privilegeName) {
            return true;
        }
        if(!privilegeKind) {
            privilegeKind = 'V'
        }

        if (privileges && privileges[privilegeName] && privileges[privilegeName][privilegeKind] === 1) {
            return true;
        }

        return false;


    },
    userIsAdmin (user) {
        return user.is_admin == true
    }
}

export default {
    install(Vue, options) {
        Vue.privileges = privileges
        Vue.prototype.$privileges = privileges
    }
}
