import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AppOptionsPlugin from "@/store/plugins/appOptions";

import AuthModule from './modules/auth.js'
import AuthUserModule from './modules/authUser.js'
import SystemUsersModule from './modules/systemUsers.js';
import AppOptionsModule from "@/store/modules/appOptions";
import PuntLogsModule from "@/store/modules/puntLogs";
import PollsModule from "@/store/modules/polls";

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    appOptions: AppOptionsModule,
    auth: AuthModule,
    authUser: AuthUserModule,
    systemUsers: SystemUsersModule,
    puntLogs: PuntLogsModule,
    polls: PollsModule
  },
  plugins: [
    createPersistedState({
      reducer: (persistedState) => {
        return {
          appOptions: JSON.parse(JSON.stringify(persistedState.appOptions))
        };
      }
    }),
    AppOptionsPlugin
  ]
})
