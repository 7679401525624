import {makeDefaultVuexModule} from "../ModuleFactory";
import * as TYPES from "../types";
import axios from 'axios';
import Vue from 'vue'
import SystemusersModule from "@/store/modules/systemUsers";

const PollsModule = makeDefaultVuexModule('POLLS', '/polls', 'created_at', 'DESC');

PollsModule.actions[TYPES.POLLS_A_SEND] = ( {commit, state, rootState, dispatch}, {id}) => {
    return new Promise( (resolve, reject) => {
        if (!state.isBusy) {
            commit(TYPES.POLLS_M_SET_BUSY)
            var url = '/polls/' + id + '/send'
            return axios.post(url).then(response => {
                commit(TYPES.POLLS_M_SET_BUSY, {isBusy: false})
                Vue.notify({
                    group: 'main',
                    type: 'success',
                    title: 'Mail z linkiem do ankiety',
                    text: 'Mail z linkiem do ankiety wysłany'
                });
                resolve(response)
            }).catch(error => {
                commit(TYPES.POLLS_M_SET_ERROR, error)
                reject(error)
            });
        } else {
            reject('busy')
        }
    })
}
export default PollsModule;
