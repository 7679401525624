import axios from 'axios';
import router from '@/config/AppRoutes';
import * as CONSTANTS from '@/constants'
import * as TYPES from '@/store/types.js';
import $ from 'jquery';

const AuthModule = {
  state: {
    token: null,
    isAuthorized: false,
    isBusy: false,
    error: null,
    tokenExpire: null,
    logoutTimeoutId: null,
    waitForUserToRefreshTimeoutId: null,
    api_version: ''
  },
  getters: {
    [TYPES.AUTH_GET_TOKEN] (state) {
      return state.token
    },
    getAuthError (state) {
      return state.error
    },
    isAuthorized (state) {
      return state.isAuthorized
    },
    isAuthBusy (state) {
      return state.isBusy
    }
  },
  mutations: {
    [TYPES.AUTH_SET_DATA] (state, value) {
      state.token = value.token
      state.isAuthorized = true
      state.error = null
      state.isBusy = false
      state.tokenExpire = value.tokenExpire
      state.refreshToken = value.refreshToken
      const dateNow = new Date();

      localStorage.setItem(CONSTANTS.LOCALSTORAGE_TOKEN_NAME, value.token)
      localStorage.setItem(CONSTANTS.LOCALSTORAGE_REFRESH_TOKEN_NAME, value.refreshToken)
      localStorage.setItem(CONSTANTS.LOCALSTORAGE_TOKEN_EXPIRE_TIME, value.tokenExpire)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(CONSTANTS.LOCALSTORAGE_TOKEN_NAME);
    },
    [TYPES.AUTH_CLEAN_DATA] (state) {
      state.token = null
      state.isAuthorized = false
      state.error = null
      state.isBusy = false
      state.tokenExpire = null
      localStorage.removeItem(CONSTANTS.LOCALSTORAGE_TOKEN_NAME)
      localStorage.removeItem(CONSTANTS.LOCALSTORAGE_REFRESH_TOKEN_NAME)
      localStorage.removeItem(CONSTANTS.LOCALSTORAGE_TOKEN_EXPIRE_TIME)
      axios.defaults.headers.common['Authorization'] = '';
    },
    [TYPES.AUTH_SET_BUSY] (state, isBusy) {
      console.log('set busy', isBusy);
      state.isBusy = isBusy
    },
    [TYPES.AUTH_SET_API_VERSION] (state, apiVersion) {
      state.api_version = apiVersion
    },
    setAuthError (state, error) {
      state.error = error
      state.isBusy = false
    },
    setAuthTimers (state, value) {
      state.logoutTimeoutId = value.logoutTimeoutId
      state.waitForUserToRefreshTimeoutId = value.waitForUserToRefreshTimeoutId
    }
  },
  actions: {
    login ({ commit, dispatch, state }, authData) {
      console.log('login', state.isBusy);
      return new Promise( (resolve, reject) => {

      if (!state.isBusy) {
        commit(TYPES.AUTH_SET_BUSY, true);
        axios.post('/oauth/token', authData)
          .then(response => {
              const token = response.data.access_token;
              const secondsToExpire = response.data.expires_in;
              const refreshToken = response.data.refresh_token;
              const dateNow = new Date();
              if (secondsToExpire > 0){
                  commit(TYPES.AUTH_SET_DATA, { token: token, tokenExpire: Math.round(dateNow.getTime() / 1000) + secondsToExpire, refreshToken: refreshToken});
                  dispatch('setAuthTimeouts');
                  Promise.all([
                    dispatch(TYPES.AUTH_USER_LOAD, null, {root: true}),
                  ]).then(() => {
                    console.log('redirect', authData.redirect ? 'YES' : 'NO');
                    authData.redirect ? router.push(authData.redirect) : router.push('/')
                    resolve()
                  }, (error) => {
                    console.log('error!', error);
                    reject(error)
                  });
              } else {
                commit(TYPES.AUTH_CLEAN_DATA)
                reject('token expired')
              }
          })
          .catch(error => {
            commit('setAuthError', error.response);
            reject(error)
          })
      } else {
        reject('busy');
      }
      });
    },
    setAuthorizationFromLocalStorage({ commit, dispatch, state }) {
      console.log('Authorize from localStorage')
      return new Promise( ( resolve, reject ) => {
        let token = localStorage.getItem(CONSTANTS.LOCALSTORAGE_TOKEN_NAME)

        if (token) {
          const refreshToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_REFRESH_TOKEN_NAME)
          const tokenExpire = parseInt(localStorage.getItem(CONSTANTS.LOCALSTORAGE_TOKEN_EXPIRE_TIME))
          const dateNow = new Date();
          const secondsToExpire = tokenExpire - Math.round(dateNow.getTime() / 1000)
          //const dateNow = new Date();
          if (secondsToExpire > 0) {
            commit(TYPES.AUTH_SET_DATA, { token: token, tokenExpire: tokenExpire, refreshToken: refreshToken})
            dispatch('setAuthTimeouts');
            Promise.all([
              dispatch(TYPES.AUTH_USER_LOAD, null, { root: true }),
            ]).then(() => {
              resolve()
            }, () => {
              reject()
            });

          } else {
            reject()
          }
        } else {
          reject();
        }
      });
    },
    logout ({ commit, state }) {
      if (!state.isBusy) {
        $(window).off('mousemove');
        commit(TYPES.AUTH_CLEAN_DATA)
        if (state.logoutTimeoutId) {
          clearTimeout(state.logoutTimeoutId);
          console.log('clear autologout timeout')
        }
        if (state.waitForUserToRefreshTimeoutId) {
          clearTimeout(state.waitForUserToRefreshTimeoutId);
          console.log('clear waitToRefresch timeout')
        }
        commit('setAuthTimers', {logoutTimeoutId: null, waitForUserToRefreshTimeoutId: null})
        if (router.currentRoute.path != '/login') {
          router.push('/login')
        }
      }
    },
    refreshAuthToken ( { commit, state, dispatch, rootState } ) {
      console.log('refreshAuthToken')
      if (!state.isBusy) {
        commit(TYPES.AUTH_SET_BUSY, true);
        console.log('END LISTENING MOUSEMOVE');
        $(window).off('mousemove');
        return axios.post('/oauth/token', {
          grant_type: 'refresh_token',
          refresh_token: state.refreshToken,
          username: rootState.authUser.user ? rootState.authUser.user.email: null
        })
          .then(function (response) {
            console.log('REFRESHING END SUCCESS');
            const token = response.data.access_token;
            const secondsToExpire = response.data.expires_in;
            const refreshToken = response.data.refresh_token;
            const dateNow = new Date();
            if (secondsToExpire > 0) {
              commit(TYPES.AUTH_SET_DATA, { token: token, tokenExpire: Math.round(dateNow.getTime() / 1000) + secondsToExpire, refreshToken: refreshToken})
              dispatch('setAuthTimeouts');
            }
          })
          .catch(function (error) {
            commit('setAuthError', error.response);
          });
      }
    },
    setAuthTimeouts( {state, dispatch, commit } ) {
      const timeLeftToRefresh = 600; //2h

      let logoutTimeoutId = state.logoutTimeoutId
      let waitForUserToRefreshTimeoutId = state.waitForUserToRefreshTimeoutId
      const dateNow = new Date();
      const secondsToExpire = state.tokenExpire - Math.round(dateNow.getTime() / 1000);
      if (secondsToExpire > 0) {
        if (logoutTimeoutId) {
          clearTimeout(logoutTimeoutId);
          console.log('clear autologout timeout')
        }
        console.log('set autologout timeout')
        logoutTimeoutId = setTimeout(() => {
          console.log('autologout');
          dispatch('logout')
        }, secondsToExpire * 1000);

        if (waitForUserToRefreshTimeoutId) {
          clearTimeout(waitForUserToRefreshTimeoutId);
          console.log('clear waitToRefresch timeout')
        }
        console.log('set waitForRefresh timeout')
        waitForUserToRefreshTimeoutId = setTimeout(() => {
          console.log('START LISTENING MOUSEMOVE');
          $(window).on('mousemove', null, null, () => { dispatch('refreshAuthToken') });
        }, (secondsToExpire - timeLeftToRefresh) > 0 ? (secondsToExpire - timeLeftToRefresh) * 1000 : 0);

        commit('setAuthTimers', {logoutTimeoutId, waitForUserToRefreshTimeoutId})
      }
    }
  },
}

export default AuthModule
