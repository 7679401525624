<template>

    <ValidationProvider v-slot="{ errors }" :rules="rules">

        <div class="pb-25px" style="position:relative;" >
            <div class="form-check form-switch" v-if="type === 'switch'">
                <label class="form-check-label" for="_uid">{{ label }}</label>
                <input class="form-check-input" :class="{
                    'form-check-danger': variant === 'danger',
                    'form-check-success': variant === 'success',
                    'form-check-warning': variant === 'warning',
                    'form-check-info': variant === 'info',

                }" type="checkbox" role="switch" :checked="value" @input="onInput({target: {value: ($event.target.checked ? 1 : 0)}})" :disabled="readonly" :id="_uid">
            </div>
            <div v-else-if="type === 'checkbox'" class="form-check">
                <input class="form-check-input" type="checkbox" :name="name" :checked="value" @change="onInput" :id="_uid" />
                <label class="form-check-label" for="_uid">
                    {{ label }}
                </label>
            </div>
            <CreatePasswordField v-else-if="type === 'password_create'"
                                 :value="value"
                                 @input="(val) => $emit('input', val)"
                                 @change="onChange"
                                 :name="name"
                                 :label="label"
                                 :errors="errors">
            </CreatePasswordField>
            <div v-else class="form-floating">
                <input v-if="type === 'text'"
                       type="text"
                       :name="name"
                       class="form-control h-45px fs-13px"
                       :class="{'is-invalid': errors.length > 0}"
                       :readonly="readonly"
                       :disabled="readonly"
                       :placeholder="label"
                       :value="value"
                       @input="onInput"
                       @change="onChange"
                       :id="_uid"/>
                <input v-else-if="type === 'password'"
                       type="password"
                       :name="name"
                       class="form-control h-45px fs-13px"
                       :class="{'is-invalid': errors.length > 0}"
                       :readonly="readonly"
                       :disabled="readonly"
                       :placeholder="label"
                       :value="value"
                       @input="onInput"
                       @change="onChange"
                       :id="_uid"/>
                <input v-else-if="type === 'file'"
                       type="file"
                       :name="name"
                       class="form-control h-45px fs-13px"
                       :class="{'is-invalid': errors.length > 0}"
                       :readonly="readonly"
                       :disabled="readonly"
                       :placeholder="label"
                       :filename="value"
                       @input="onInput"
                       @change="onChange"
                       :id="_uid"/>
                <date-picker v-else-if="type === 'date'"
                             :name="name"
                             input-class="form-control h-45px fs-13px"
                             :input-class="{'is-invalid': errors.length > 0}"
                             :readonly="readonly"
                             :disabled="readonly"
                             :placeholder="label"
                             :value="value"
                             @input="onInput"
                             @change="onChange"
                             :id="_uid"
                             :config="{ format: 'YYYY-MM-DD', locale: 'pl', sideBySide: true, showClose: true}"
                />
                <NumericField v-else-if="type === 'decimal'"
                              :input-class="'form-control h-45px fs-13px' + errors.length > 0 ? ' is-invalid': ''"
                              :precision="precision"
                              :readonly="readonly"
                              :disabled="readonly"
                              :placeholder="label"
                              :value="value"
                              @input="onInput"
                              :id="_uid"
                />
                <select v-else-if="type === 'select'"
                        :name="name"
                        class="form-select fs-13px"
                        :class="{'is-invalid': errors.length > 0}"
                        :readonly="readonly"
                        :disabled="readonly"
                        :placeholder="label"
                        :value="value"
                        @input="onInput"
                        @change="onChange"
                        :id="_uid">
                    <slot></slot>
                </select>
                <template v-else>
                    <div class="form-control h-auto">
                        <slot :v="value" :i="onInput" :c="onChange"></slot>
                    </div>
                </template>
                <label :for="_uid" class="d-flex align-items-center fs-14px" :class="labelClass">{{ label }}</label>
            </div>
            <div class="invalid-feedback" style="display: block;position: absolute; bottom: 7px;" v-show="errors.length > 0">{{ errors[0] }}</div>
        </div>
    </ValidationProvider>

</template>

<script>
import CreatePasswordField from "@/components/Forms/CreatePasswordField";
import NumericField from "@/components/Forms/NumericField.vue";
export default {
    name: "InputField",
    components: {
        CreatePasswordField,
        NumericField,
    },
    props: {
        type: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: String,
            required: false,
            default: ''
        },
        value: {
            required: false,
            default: null
        },
        labelClass: {
            required: false,
            default: null,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        variant: {
            type: String,
            required: false,
            default: ''
        },
        precision: {
            type: Number,
            required: false,
            default: 0,
        }
    },

    data() {
        return {
            test: null
        }
    },

    methods: {
        onInput(val) {
            console.log('onInput', val);
            this.$emit('input', val.target ? val.target.value: val);
        },
        onChange(val) {
            this.$emit('change', val);
        }
    }
}
</script>

<style scoped>

</style>