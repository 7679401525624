<template>
    <div class="input-group mb-3">
        <div class="form-floating" >
            <input class="form-control h-45px fs-13px" :class="{'is-invalid': errors.length > 0}" :type="show ? 'text' : 'password'" :value="value" @input="$emit('input', $event.target.value)" :name="name" :id="_uid" :placeholder="label"></input>
            <label :for="_uid" class="d-flex align-items-center fs-14px" :class="labelClass">{{ label }}</label>
        </div>
        <span class="input-group-text" id="basic-addon-1" @click="show = !show"><em class="fas" :class="{'fa-eye' : show, 'fa-eye-slash' : !show}"></em></span>
        <div class="invalid-feedback" style="display: block;">
            <ul>
                <li :class="{'notfulfilled' : !containdSmallLetter, 'fulfilled': containdSmallLetter}">mała litera</li>
                <li :class="{'notfulfilled' : !containsLargeLetter, 'fulfilled': containsLargeLetter}">duża litera</li>
                <li :class="{'notfulfilled' : !containsDigit, 'fulfilled': containsDigit}">cyfra</li>
                <li :class="{'notfulfilled' : !containsSpecialCharacter, 'fulfilled': containsSpecialCharacter}">znak specjalny (!@#$%^&*)</li>
                <li :class="{'notfulfilled' : !isRequiredLength, 'fulfilled': isRequiredLength}">minimum 8 znaków</li>
            </ul>
        </div>
    </div>
</template>

<script>
import PasswordField from '@/components/Forms/PasswordField'
export default {
    name: "CreatePasswordField",
    components: {
        PasswordField,
    },
    props: [
        'value',
        'name',
        'placeholder',
        'label',
        'labelClass',
        'errors'
    ],
    data() {
        return {
            show: true
        }
    },
    computed: {
        isRequiredLength() {
            return this.value && this.value.length >= 8
        },
        containdSmallLetter() {
            let passwordPattern = /^(?=.*[a-z]).+$/;
            return passwordPattern.test(this.value);
        },
        containsLargeLetter() {
            let passwordPattern = /^(?=.*[A-Z]).+$/;
            return passwordPattern.test(this.value);
        },
        containsDigit() {
            let passwordPattern = /^(?=.*\d).+$/;
            return passwordPattern.test(this.value);
        },
        containsSpecialCharacter() {
            let passwordPattern = /^(?=.*(_|[^\w])).+$/;
            return passwordPattern.test(this.value);
        }
    }
}
</script>

<style scoped>
.notfulfilled {
    color:red !important;
}
.fulfilled {
    color:#AAAAAA;
    text-decoration: line-through;
}
</style>
\
