export const AUTH_GET_TOKEN = '_AUTH_GET_TOKEN'
export const AUTH_SET_DATA = '_AUTH_SET_DATA'
export const AUTH_CLEAN_DATA = '_AUTH_CLEAN_AUTH_DATA'
export const AUTH_SET_BUSY = '_AUTH_SET_BUSY'
export const AUTH_SET_API_VERSION = '_AUTH_SET_API_VERSION'

export const AUTH_USER_GET_DATA = '_AUTH_USER_GET_DATA'
export const AUTH_USER_SET_DATA = '_AUTH_USER_SET_DATA'
export const AUTH_USER_SET_BUSY = '_AUTH_USER_SET_BUSY'
export const AUTH_USER_SET_ERROR = '_AUTH_USER_SET_ERROR'
export const AUTH_USER_LOAD = '_AUTH_USER_LOAD'
export const AUTH_USER_SAVE = '_AUTH_USER_SAVE'
export const AUTH_USER_SET_NEW_PASSWORD = '_AUTH_USER_SET_NEW_PASSWORD'
export const AUTH_USER_A_UPLOAD_AVATAR = '_AUTH_USER_A_UPLOAD_AVATAR'

//// SYSTEMUSERS ////
export const SYSTEMUSERS_G_GET_ITEMS = '_SYSTEMUSERS_G_GET_ITEMS'
export const SYSTEMUSERS_G_GET_ALL_ITEMS = '_SYSTEMUSERS_G_GET_ALL_ITEMS';
export const SYSTEMUSERS_G_GET_SELECTED = '_SYSTEMUSERS_G_GET_SELECTED'
export const SYSTEMUSERS_G_GET_IS_BUSY = '_SYSTEMUSERS_G_GET_IS_BUSY'
export const SYSTEMUSERS_G_GET_ERROR = '_SYSTEMUSERS_G_GET_ERROR'

export const SYSTEMUSERS_M_ADD_ITEMS = '_SYSTEMUSERS_M_ADD_ITEMS'
export const SYSTEMUSERS_M_ADD_ALL_ITEMS = '_SYSTEMUSERS_M_ADD_ALL_ITEMS'
export const SYSTEMUSERS_M_SET_SELECTED = '_SYSTEMUSERS_M_SET_SELECTED'
export const SYSTEMUSERS_M_SET_BUSY = '_SYSTEMUSERS_M_SET_BUSY'
export const SYSTEMUSERS_M_SET_ERROR = '_SYSTEMUSERS_M_SET_ERROR'

export const SYSTEMUSERS_A_LOAD = '_SYSTEMUSERS_A_LOAD'
export const SYSTEMUSERS_A_LOAD_ALL = '_SYSTEMUSERS_A_LOAD_ALL'
export const SYSTEMUSERS_A_LOAD_SELECTED = '_SYSTEMUSERS_A_LOAD_SELECTED'
export const SYSTEMUSERS_A_SAVE_SELECTED = '_SYSTEMUSERS_A_SAVE_SELECTED'
export const SYSTEMUSERS_A_DELETE_SELECTED = '_SYSTEMUSERS_A_DELETE_SELECTED'
export const SYSTEMUSERS_A_UPLOAD_AVATAR = '_SYSTEMUSERS_A_UPLOAD_AVATAR'
export const SYSTEMUSERS_A_RESET_PASSWORD = '_SYSTEMUSERS_A_RESET_PASSWORD'
export const SYSTEMUSERS_A_ATTACH_SPECIALIZATION = '_SYSTEMUSERS_A_ATTACH_SPECIALIZATION'
export const SYSTEMUSERS_A_DETACH_SPECIALIZATION = '_SYSTEMUSERS_A_DETACH_SPECIALIZATION'
export const SYSTEMUSERS_A_ATTACH_DISEASE_EXCLUSION = '_SYSTEMUSERS_A_ATTACH_DISEASE_EXCLUSION'
export const SYSTEMUSERS_A_DETACH_DISEASE_EXCLUSION = '_SYSTEMUSERS_A_DETACH_DISEASE_EXCLUSION'

//// PUNTLOGS ////
export const PUNTLOGS_G_GET_ITEMS = '_PUNTLOGS_G_GET_ITEMS'
export const PUNTLOGS_G_GET_ALL_ITEMS = '_PUNTLOGS_G_GET_ALL_ITEMS';
export const PUNTLOGS_G_GET_SELECTED = '_PUNTLOGS_G_GET_SELECTED'
export const PUNTLOGS_G_GET_IS_BUSY = '_PUNTLOGS_G_GET_IS_BUSY'
export const PUNTLOGS_G_GET_ERROR = '_PUNTLOGS_G_GET_ERROR'

export const PUNTLOGS_M_ADD_ITEMS = '_PUNTLOGS_M_ADD_ITEMS'
export const PUNTLOGS_M_ADD_ALL_ITEMS = '_PUNTLOGS_M_ADD_ALL_ITEMS'
export const PUNTLOGS_M_SET_SELECTED = '_PUNTLOGS_M_SET_SELECTED'
export const PUNTLOGS_M_SET_BUSY = '_PUNTLOGS_M_SET_BUSY'
export const PUNTLOGS_M_SET_ERROR = '_PUNTLOGS_M_SET_ERROR'

export const PUNTLOGS_A_LOAD = '_PUNTLOGS_A_LOAD'
export const PUNTLOGS_A_LOAD_ALL = '_PUNTLOGS_A_LOAD_ALL'
export const PUNTLOGS_A_LOAD_SELECTED = '_PUNTLOGS_A_LOAD_SELECTED'
export const PUNTLOGS_A_SAVE_SELECTED = '_PUNTLOGS_A_SAVE_SELECTED'
export const PUNTLOGS_A_DELETE_SELECTED = '_PUNTLOGS_A_DELETE_SELECTED'


//// POLLS ////
export const POLLS_G_GET_ITEMS = '_POLLS_G_GET_ITEMS'
export const POLLS_G_GET_ALL_ITEMS = '_POLLS_G_GET_ALL_ITEMS';
export const POLLS_G_GET_SELECTED = '_POLLS_G_GET_SELECTED'
export const POLLS_G_GET_IS_BUSY = '_POLLS_G_GET_IS_BUSY'
export const POLLS_G_GET_ERROR = '_POLLS_G_GET_ERROR'

export const POLLS_M_ADD_ITEMS = '_POLLS_M_ADD_ITEMS'
export const POLLS_M_ADD_ALL_ITEMS = '_POLLS_M_ADD_ALL_ITEMS'
export const POLLS_M_SET_SELECTED = '_POLLS_M_SET_SELECTED'
export const POLLS_M_SET_BUSY = '_POLLS_M_SET_BUSY'
export const POLLS_M_SET_ERROR = '_POLLS_M_SET_ERROR'

export const POLLS_A_LOAD = '_POLLS_A_LOAD'
export const POLLS_A_LOAD_ALL = '_POLLS_A_LOAD_ALL'
export const POLLS_A_LOAD_SELECTED = '_POLLS_A_LOAD_SELECTED'
export const POLLS_A_SAVE_SELECTED = '_POLLS_A_SAVE_SELECTED'
export const POLLS_A_DELETE_SELECTED = '_POLLS_A_DELETE_SELECTED'

export const POLLS_A_SEND = '_POLLS_A_SEND'