<template>
    <div class="h-100" v-else>
        <router-view></router-view>
        <vue-ins-progress-bar></vue-ins-progress-bar>
    </div>
</template>

<script>

export default {
    name: 'app',
    components: {

    },
    data() {
        return {

        }
    },
    methods: {

    },
    mounted () {
        this.$insProgress.finish()
    },
    created() {
        this.$insProgress.start()
    }
}
</script>
