import Vue from 'vue'
import VueX from 'vuex'
import Store from '@/store/index'
import router from './config/AppRoutes'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueCustomScrollbar from 'vue-custom-scrollbar'

// plugins css
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'vue-slider-component/theme/default.css'

// color admin css
import './scss/vue.scss'

import App from './App.vue'

Vue.config.productionTip = true

Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)

//vee-validate
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {messages} from 'vee-validate/dist/locale/pl.json';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver)
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

import VueSlider from 'vue-slider-component';
Vue.component('VueSlider', VueSlider);
//vue-js-modal
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal,{ dynamic: true })

//privileges
import Privileges from '@/plugins/Privileges';
Vue.use(Privileges)

//AppSettings
import AppSettings from "@/plugins/AppSettings";
Vue.use(AppSettings);

//filters
import IntegerFilter from '@/Filters/IntegerFilter';
Vue.filter('integer', IntegerFilter);

import DecimalFilter from "@/Filters/DecimalFilter";
Vue.filter('decimal', DecimalFilter)

import CurrencyFilter from "@/Filters/CurrencyFilter";
Vue.filter('currency', CurrencyFilter)

import DateFilter from "@/Filters/DateFilter";
Vue.filter('date', DateFilter);

import TimeFilter from "@/Filters/TimeFilter";
Vue.filter('time', TimeFilter);

import SecondsToTimeFilter from "@/Filters/SecondsToTimeFilter";
Vue.filter('secondsToTime', SecondsToTimeFilter);

import DateTimeFilter from "@/Filters/DateTimeFilter";
Vue.filter('datetime', DateTimeFilter);

//moment
import moment from 'moment'
moment.locale('pl', {
  week: {
    dow: 1
  },
  months: [
    'styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'
  ]
})

//axios
import '@/plugins/Axios';

import Loading from "@/components/Common/Loading";
Vue.component('app-loading', Loading);

import Busy from '@/components/Common/Busy';
Vue.component('app-busy', Busy);

import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
Vue.use(Croppa);

import ImgSrcWithAuthDirective from "@/Directives/ImgSrcWithAuth";
Vue.directive('src-with-auth', ImgSrcWithAuthDirective);

new Vue({
  render: h => h(App),
  router,
  store: Store
}).$mount('#app')