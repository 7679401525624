<template>
        <input type="text" :value="val" @input="onInput" @keypress="onlyForCurrency" @blur="formatNumber" :name="name" class="text-end form-control" ref="inputfield" :class="inputClass" :readonly="readonly"></input>
</template>

<script>
export default {
    name: "NumericField",
    props: {
        value : {type: [String, Number]},
        name: {type: String},
        placeholder: {type: String},
        inputClass: {type: String},
        precision: {
            type: Number,
            required: false,
            default: 2
        },
        separator: {
            type: String,
            required: false,
            default: ','
        },
        currency: {
            type: String,
            required: false,
            default: null
        },
        postfix: {
            type: String,
            required: false,
            default: null
        },
        postfixActive: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            dafautl: false
        }
    },
    data() {
        return {

        }
    },
    watch: {
        precision(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.formatNumber();
            }
        }
    },
    computed: {
        val() {
            let ret = this.value;
            if (!ret) {
                ret = 0;
            }
            console.log('type', typeof ret);
            if (typeof ret === 'number') {
                ret = ret.toFixed(this.precision);
                console.log('val', ret);
            }
            ret = ret.replace('.', this.separator);

            return ret;
        }
    },
    methods: {
        onInput(event) {
            console.log('onInput', typeof event.target.value);
            let val = event.target.value ? event.target.value.replace(this.separator, '.') : '0';
            this.$emit('input', {target: {value: val }})
        },
        onlyForCurrency ($event) {
            // let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            // // only allow number and one dot
            // if ((keyCode < 48 || keyCode > 57) && (String.fromCharCode(keyCode) != this.separator  || (this.value.indexOf(".") != -1 && this.value.substring(this.$refs.inputfield.selectionStart, this.$refs.inputfield.selectionEnd).indexOf(".") == -1))) { // 46 is dot
            //     $event.preventDefault();
            // }

            // restrict to 2 decimal places
            // if(this.value!=null && this.value.indexOf(".")>-1 && (this.value.split(".")[1].length - (this.$refs.inputfield.selectionEnd - Math.max(this.$refs.inputfield.selectionStart, this.value.indexOf(".")))> this.precision - 1 && this.$refs.inputfield.selectionEnd >= this.value.indexOf(".") )){
            //     $event.preventDefault();
            // }

            return true;
        },
        formatNumber() {
            this.$emit('input', {target: {value: parseFloat(this.value).toFixed(this.precision)}})
            this.$emit('blur', {target: {value: parseFloat(this.value).toFixed(this.precision)}})
        },
        setInputFocus() {
            this.$refs.inputfield.focus();
            setTimeout(() => {
                this.$refs.inputfield.select();
            }, 50)
        },
        onClickPostfix() {
            if (this.postfixActive) {
                this.$emit('click-postfix');
            }
        },
    },
    mounted() {
        this.$emit('input', {target: {value: parseFloat(this.value ? this.value : '0').toFixed(this.precision)}})
    },
    beforeUpdate() {
        if (this.$refs.inputfield !== document.activeElement) {
            this.formatNumber();
        }
    }
}
</script>

<style scoped>

</style>
