<template>
    <div class="loading-overlay">
        <div class="text-center">
            <i class="fa fa-circle-notch fa-spin fa-3x"></i>
        </div>
        <div class="text text-center">Proszę czekaj...</div>
    </div>
</template>

<script>
  export default {
    name: 'Busy'
  }
</script>

<style scoped>

</style>
