<template>
	<div class="panel" v-bind:class="[{ 'panel-expand': expand, 'panel-loading': reload }, panelTheme, panelClass]" v-if="!remove">
		<div class="panel-heading" v-bind:class="headerClass" style="align-items: start !important;">

			<h4 class="panel-title" >
                <slot name="header">{{title}}</slot>
			</h4>
			<div class="panel-heading-btn"  v-if="!noButton">
				<a v-if="!noButtonExpand" href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" v-on:click="panelExpand"><i class="fa fa-expand"></i></a>
				<a v-if="$listeners && $listeners.reload" href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" v-on:click="panelReload"><i class="fa fa-redo"></i></a>
				<a v-if="!noButtonCollapse" href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" v-on:click="panelCollapse"><i class="fa fa-minus"></i></a>
				<a v-if="$listeners && $listeners.close"href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" v-on:click="panelRemove"><i class="fa fa-times"></i></a>
			</div>
		</div>
		<slot name="beforeBody"></slot>
		<div class="panel-body" v-if="!noBody" v-show="!collapse" v-bind:class="bodyClass">
			<slot></slot>
			<div class="panel-loader" v-if="reload"><span class="spinner spinner-sm"></span></div>
		</div>
		<slot v-else></slot>
		<slot name="outsideBody"></slot>
		<div class="panel-footer" v-if="hasFooterSlot" v-bind:class="footerClass">
			<slot name="footer"></slot>
		</div>
		<slot name="afterFooter"></slot>
	</div>
</template>

<script>
export default {
	name: 'Panel',
	props: {
        'title': '',
        'body': '',
        'footer': '',
        'variant': '',
        'noBody': false,
        'noButton': false,
        'headerClass': '',
        'bodyClass': '',
        'footerClass': '',
        'panelClass': '',
        'loading': {
            type: Boolean,
            required: false,
            default: false
        },

        'noButtonExpand': {
            type: Boolean,
            required: false,
            default: false,
        },
        'noButtonCollapse': {
            type: Boolean,
            required: false,
            default: false,
        },

    },
	data() {
		return {
			expand: false,
			collapse: false,
			remove: false,
			reload: false,
			panelTheme: (this.variant) ? 'panel-' + this.variant : 'panel-inverse'
		}
	},
	computed: {
		hasFooterSlot() {
			return !!this.$slots.footer;
		},
		hasHeaderSlot() {
			return !!this.$slots.header;
		}
	},
	methods: {
		panelExpand: function() {
            this.$emit('expand');
			this.expand = !this.expand
		},
		panelCollapse: function() {
            this.$emit('collapse')
			this.collapse = !this.collapse
		},
		panelRemove: function() {
            this.$emit('close');
			this.remove = !this.remove
		},
		panelReload: function() {
            this.$emit('reload');
		},
	}
}
</script>