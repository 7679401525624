import {makeDefaultVuexModule} from "../ModuleFactory";
import * as TYPES from "../types";
import axios from 'axios';
import Vue from 'vue'

const SystemusersModule = makeDefaultVuexModule('SYSTEMUSERS', '/systemusers', 'name', 'ASC');

SystemusersModule.actions[TYPES.SYSTEMUSERS_A_UPLOAD_AVATAR] = ( {commit, state, rootState, dispatch}, {id, imageBlob}) => {
    return new Promise( (resolve, reject) => {
        if (!state.isBusy) {
            commit(TYPES.SYSTEMUSERS_M_SET_BUSY)
            var url = '/systemusers/' + id + '/upload_avatar'
            var data = new FormData()
            data.append('file', imageBlob);
            return axios.post(url, data).then(response => {
                commit(TYPES.SYSTEMUSERS_M_SET_BUSY, {isBusy: false})
                Vue.notify({
                    group: 'main',
                    type: 'success',
                    title: 'Edycja użytkownika',
                    text: 'Zapis danych zakończony sukcesem'
                });
                if (id === rootState.authUser.user.id) {
                    dispatch(TYPES.AUTH_USER_LOAD, null, {root: true})
                }
                dispatch(TYPES.SYSTEMUSERS_A_LOAD_SELECTED, id)
                resolve(response)
            }).catch(error => {
                commit(TYPES.SYSTEMUSERS_M_SET_ERROR, error)
                reject(error)
            });
        } else {
            reject('busy')
        }
    })
}

SystemusersModule.actions[TYPES.SYSTEMUSERS_A_RESET_PASSWORD] = ({commit, state}, userData) => {
    return new Promise( (resolve, reject) => {
        if (!state.isBusy) {
            commit(TYPES.SYSTEMUSERS_M_SET_BUSY)
            return axios.post('/systemusers/' + userData.id + '/reset_password')
                .then(response => {
                    commit(TYPES.SYSTEMUSERS_M_SET_BUSY, {isBusy: false})
                    Vue.notify({
                        group: 'main',
                        type: 'success',
                        title: 'Reset hasła',
                        text: 'Do użytkownika wysłana została wiadomość z nowym hasłem tymczasowym'
                    });
                    resolve(response.data)
                }).catch(error => {
                    commit(TYPES.SYSTEMUSERS_M_SET_ERROR, error)
                    reject(error)
                })
        } else {
            reject('busy')
        }
    })
}

export default SystemusersModule;
