<template>
    <div class="mt-2">
        <div v-if="timeout && !isAuthBussy" :class="{'text-warning': timeout.asMinutes() < 10 && timeout.asMinutes() >= 5, 'text-danger': timeout.asMinutes() < 5} ">
            <span v-if="!showOnlyTime">Sesja wygaśnie za: </span><template v-if="timeout.hours() != '00'">{{ timeout.hours() }}g </template>{{ timeout.minutes() }}m {{ timeout.seconds() }}s</div>
        <div v-if="isAuthBussy">trwa odświeżanie</div>
    </div>
</template>

<script>
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import store from '@/store/index'
import { mapGetters } from 'vuex'
import * as TYPES from '@/store/types';

export default {
    name: 'SessionTimeoutInfo',
    data() {
        return {
            timeout: null,
            intervalId: null
        }
    },
    props: ['showOnlyTime'],
    computed: {
        ...mapGetters({
            isAuthBussy: 'isAuthBusy',
        })
    },
    methods: {

    },
    created() {
        this.intervalId = setInterval(() => {
            const dateNow = new Date();
            const tokenExpire = store.state.auth.tokenExpire;
            const secondsToExpire = tokenExpire - Math.round(dateNow.getTime() / 1000)
            // const hours = Math.floor(secondsToExpire / 3600)
            // const min = Math.floor((secondsToExpire - hours * 3600 ) / 60);
            // const sec = secondsToExpire - hours * 3600 - min * 60;

            //console.log('timeout:', {hour:hours, minute: min, seconds: sec})

            this.timeout = moment.duration(secondsToExpire, 'seconds');
        }, 1000);
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }
}
</script>

<style scoped>

</style>
