<script>
import Home from '../pages/Home.vue'
import VueRouter from "vue-router";
import Vue from 'vue'
import store from '@/store/index'

import LayoutFull from "@/components/Layout/LayoutFull";
import LayoutEmpty from "@/components/Layout/LayoutEmpty";

import UserLogin from '@/pages/login/User-login-v3';

import * as PRIVILEGES from '@/config/Privileges'

const routes = [
    {
        path: '/',
        redirect: '/start'
    },
    {
        path: '/',
        meta: {
            requiresAuth: true
        },
        component: LayoutFull,
        children: [
            {path: '/start', component: Home},

            {path: '/test', component: () => import('@/pages/TestPage')},
            {
                path: '/profile',
                component: () => import('@/pages/Profile/ProfilePage')
            },
            {
                path: '/systemusers',
                component: () => import('@/pages/SystemUsers/ListPage'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, king: PRIVILEGES.PRIVILEGE_KIND_VIEW}}
            },
            {
                path: '/systemusers/:id',
                component: () => import('@/pages/SystemUsers/DetailsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}}
            },
            {
                path: '/logs',
                component: () => import('@/pages/Logs/ListPage'),
            },
            {
                path: '/import',
                component: () => import('@/pages/Import/MainPage'),
            },
            {
                path: '/polls',
                component: () => import('@/pages/Polls/ListPage.vue'),
            },
            {
                path: '/polls/upload',
                component: () => import('@/pages/Polls/UploadPage'),
            },
            {
                path: '/discounts',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'RABATY',
                    loadEndpoinUrl: 'discounts-company',
                    updateEndpointUrl: 'discounts',
                    discountFieldName: 'punt_yacht_discount'
                }
            },
            {
                path: '/discounts-hot-deal',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'RABATY "HOT DEAL"',
                    loadEndpoinUrl: 'discounts-hot-deal-company',
                    updateEndpointUrl: 'discounts-hot-deal',
                    discountFieldName: 'punt_yacht_discount_hot_deal'
                }
            },
            {
                path: '/discounts-max',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'RABATY "MAX"',
                    loadEndpoinUrl: 'discounts-max-company',
                    updateEndpointUrl: 'discounts-max',
                    discountFieldName: 'punt_yacht_discount_max'
                }
            },
            {
                path: '/discounts-promo',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'RABATY "PROMO"',
                    loadEndpoinUrl: 'discounts-promo-company',
                    updateEndpointUrl: 'discounts-promo',
                    discountFieldName: 'punt_yacht_discount_promo'
                }
            },
            {
                path: '/discounts-repeater',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'RABATY "REPEATER"',
                    loadEndpoinUrl: 'discounts-repeater-company',
                    updateEndpointUrl: 'discounts-repeater',
                    discountFieldName: 'punt_yacht_discount_repeater'
                }
            },
            {
                path: '/yacht-highlights',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'Highlights',
                    loadEndpoinUrl: 'yacht-highlights-company',
                    updateEndpointUrl: 'yacht-highlights',
                    discountFieldName: 'punt_yacht_highlight_id',
                    discountFieldPrecision: 0,
                    discountFieldLabel: 'HIGHLIGHT ID'
                }
            },
            {
                path: '/yacht-promotions',
                component: () => import('@/pages/Discounts/DiscountsPage.vue'),
                meta: { requiresRight: { name: PRIVILEGES.PRIVILEGE_SYSTEMUSERS, kind: PRIVILEGES.PRIVILEGE_KIND_VIEW}},
                props: {
                    pageTitle: 'Promocje',
                    loadEndpoinUrl: 'yacht-promotions-company',
                    updateEndpointUrl: 'yacht-promotions',
                    discountFieldName: 'punt_yacht_promotion_id',
                    discountFieldPrecision: 0,
                    discountFieldLabel: 'PROMOTION ID'
                }
            },
        ]
    },
    {
        path: '/',
        component: LayoutEmpty,
        children: [
            {
                path: '/login',
                component: UserLogin
            },
            {
                path: '/password',
                component: () => import('@/pages/ChangePassword/ChangePassword'),
                meta: { requiresAuth: true }
            },
            {
                path: '/password_expired',
                component: () => import('@/pages/ChangePassword/ChangePassword'),
                meta: { requiresAuth: true, expired: true }
            },

        ]
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

function checkRoutePrivilege (to, from, next) {
    if (to.matched.some(record => record.meta.requiresRight))  {
        const nearestWithRequiredRight = to.matched.slice().reverse().find(r => r.meta && r.meta.requiresRight);
        const requiredRight =  nearestWithRequiredRight.meta.requiresRight
        if (Vue.privileges.loggedUserHasRight(requiredRight.name, requiredRight.kind)) {
            next()
        } else {
            console.log('Brak uprawnień!');
            Vue.notify({
                group: 'main',
                type: 'error',
                title: 'Brak uprawnień',
                text: 'Nie masz uprawnień'
            });
            next(from.path)
        }

    } else {
        next();
    }
}

router.beforeEach((to, from, next) => {
    console.log('router beforeEach')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log('router requiresAuth')
        if (!store.state.auth.isAuthorized)  {
            store.dispatch('setAuthorizationFromLocalStorage').finally( () => {
                if (!store.state.auth.isAuthorized) {
                    next({
                        path: '/login',
                        query: { redirect: to.fullPath }
                    });
                } else {
                    const user = store.state.authUser.user;
                    if (to.path !== '/password' && to.path !== '/password_expired' && user.temp_password !== '') {
                        next({
                            path: '/password',
                            query: {redirect: to.fullPath}
                        });
                    } else if (to.path !== '/password' && to.path !== '/password_expired' && (user.password_valid_date === null || moment(user.password_valid_date).isBefore())) {
                        next({
                            path: '/password_expired',
                            query: {redirect: to.fullPath}
                        });

                    } else {
                        checkRoutePrivilege(to, from, next)
                    }
                }
            });
        } else {
            const user = store.state.authUser.user;

            if (to.path !== '/password' && to.path !== '/password_expired' && user.temp_password !== '') {
                next({
                    path: '/password',
                    query: {redirect: to.fullPath}
                });
            } else if (to.path !== '/password' && to.path !== '/password_expired' && (user.password_valid_date === null || moment(user.password_valid_date).isBefore())) {
                next({
                    path: '/password_expired',
                    query: {redirect: to.fullPath}
                });

            } else {
                checkRoutePrivilege(to, from, next)
            }
        }
    } else {
        next()
    }
})

export default router;
</script>
