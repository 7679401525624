export default function DecimalFilter(value, currency = 'zł') {
let _val = 0.00;
if (typeof value === 'string') {
_val = parseFloat(value);
} else {
_val = value;
}
if (_val === null) {
    _val = 0;
}
return _val.toFixed(2).replace('.', ',') + (currency && currency.length > 0 ? ' ' + currency : '');
}
