<template>
	<div class="menu">
		<div class="menu-profile">
			<a href="javascript:;" class="menu-profile-link" v-on:click="expand()">
				<div class="menu-profile-cover with-shadow"></div>
				<div class="menu-profile-image menu-profile-image-icon bg-gray-900 text-gray-600">
                    <img v-if="authUser.picture" v-src-with-auth="authUser.picture_download_url">
					<i v-else class="fa fa-user"></i>
				</div>
				<div class="menu-profile-info" v-if="authUser">
					<div class="d-flex align-items-center">
						<div class="flex-grow-1">
                            {{authUser.name}}
						</div>
						<div class="menu-caret ms-auto"></div>
					</div>
                    <small>
                        <session-timeout-info></session-timeout-info>
                    </small>
				</div>
			</a>
		</div>
		<div id="appSidebarProfileMenu" class="collapse"  v-bind:class="{ 'd-block': this.stat == 'expand' && !this.appOptions.pageSidebarMinified, 'd-none': this.stat == 'collapse' }">
			<router-link to="/profile" class="menu-item pt-5px" active-class="active" tag="div">
				<a class="menu-link" >
					<div class="menu-icon"><i class="fa fa-cog"></i></div>
					<div class="menu-text">Profil</div>
				</a>
            </router-link>
			<div class="menu-item pb-5px">
				<a href="javascript:;" @click="onLogut" class="menu-link">
					<div class="menu-icon"><i class="fa fa-sign-out"></i></div>
					<div class="menu-text">Wyloguj</div>
				</a>
			</div>

			<div class="menu-divider m-0"></div>
		</div>
	</div>
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import {mapGetters} from "vuex";
import * as TYPES from "@/store/types";
import SessionTimeoutInfo from "@/components/Layout/sidebar/SessionTimeoutInfo";

export default {
	name: 'SidebarNavProfile',
    components: {SessionTimeoutInfo},
    computed: {
        ...mapGetters({
            authUser: TYPES.AUTH_USER_GET_DATA
        })
    },
	data() {
		return {
			stat: '',
			appOptions: AppOptions
		}
	},
	methods: {
        onLogut() {
            this.$store.dispatch('logout');
        },
		expand: function() {
			this.stat = (this.stat == 'expand') ? 'collapse' : 'expand'
		}
  }
}
</script>
