import AppOptions from "@/config/AppOptions";

const AppOptionsModule = {
    state: {
        appTheme: AppOptions.appTheme,
        appDarkMode: AppOptions.appDarkMode,
        appSidebarFixed: AppOptions.appSidebarFixed,
        appHeaderInverse: AppOptions.appHeaderInverse,
        appSidebarGrid: AppOptions.appSidebarGrid,
        appGradientEnabled: AppOptions.appGradientEnabled,
        appHeaderFixed: AppOptions.appHeaderFixed
    },
    getters: {
        getAppOptions (state) {
            return state;
        }
    },
    mutations: {
        changeAppOption (state, {name, value}) {
            if (name in state) {
                state[name] = value;
            }
        }
    },
    actions: {}
}

export default AppOptionsModule;