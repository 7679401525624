<template>
    <div>
        <!-- BEGIN #header -->
        <div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
            <!-- BEGIN navbar-header -->
            <div class="navbar-header">
                <button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarEndMobile" v-if="appOptions.appSidebarTwo">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <router-link to="/" class="navbar-brand" style="padding-left:5px;"><img class="logo-punt" src="/assets/img/logo-punt-single.png"> <b>{{ $appSettings.appName }}</b> Admin</router-link>
                <button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && !appOptions.appSidebarNone">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
                </button>
                <button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && appOptions.appSidebarNone">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <button type="button" class="navbar-mobile-toggler" v-on:click="toggleHeaderMegaMenuMobile" v-if="appOptions.appHeaderMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
                </button>
                <button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarMobile" v-if="!appOptions.appSidebarNone">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
            </div>
            <!-- END navbar-header -->


            <!-- BEGIN header-nav -->
            <div class="navbar-nav">
                <header-mega-menu v-if="appOptions.appHeaderMegaMenu"></header-mega-menu>
                <b-nav-item-dropdown right menu-class="me-1" class="navbar-item navbar-user dropdown" toggle-class="navbar-link dropdown-toggle d-flex align-items-center" no-caret>
                    <template slot="button-content">
                        <img v-if="authUser.picture" v-src-with-auth="authUser.picture_download_url">
                        <div v-else class="image image-icon bg-gray-800 text-gray-600">
                            <i class="fa fa-user"></i>
                        </div>
                        <span class="d-none d-md-inline">{{ authUser.name }}</span> <b class="caret"></b>
                    </template>
                    <a href="javascript:;" class="dropdown-item">Edytuj Profil</a>

                    <div class="dropdown-divider"></div>
                    <a @click="onLogout" class="dropdown-item">Wyloguj</a>
                </b-nav-item-dropdown>
                <div class="navbar-divider d-none d-md-block" v-if="appOptions.appSidebarTwo"></div>
                <div class="navbar-item d-none d-md-block" v-if="appOptions.appSidebarTwo">
                    <a href="javascript:;" v-on:click="toggleSidebarEnd" class="navbar-link icon">
                        <i class="fa fa-th"></i>
                    </a>
                </div>
            </div>
            <!-- end header navigation right -->
        </div>
        <!-- end #header -->
    </div>
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import * as TYPES from '@/store/types';
import {mapGetters} from 'vuex'

export default {
    name: 'Header',
    components: {
        HeaderMegaMenu
    },
    computed: {
        ...mapGetters({
            authUser: TYPES.AUTH_USER_GET_DATA
        })
    },
    data() {
        return {
            appOptions: AppOptions
        }
    },
    methods: {
        onLogout() {
            this.$store.dispatch('logout');
        },
        toggleSidebarMobile() {
            this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled;
        },
        toggleSidebarEnd() {
            this.appOptions.appSidebarEndToggled = !this.appOptions.appSidebarEndToggled;
        },
        toggleSidebarEndMobile() {
            this.appOptions.appSidebarEndMobileToggled = !this.appOptions.appSidebarEndMobileToggled;
        },
        toggleTopMenuMobile() {
            this.appOptions.appTopMenuMobileToggled = !this.appOptions.appTopMenuMobileToggled;
        },
        toggleHeaderMegaMenuMobile() {
            this.appOptions.appHeaderMegaMenuMobileToggled = !this.appOptions.appHeaderMegaMenuMobileToggled;
        },
        checkForm: function(e) {
            e.preventDefault();
            this.$router.push({ path: '/extra/search' })
        }
    }
}
</script>
