<template>
    <div>
        <!-- BEGIN breadcrumb -->
        <div class="d-flex align-items-center mb-3">
            <div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><span class="fas fa-home"></span></router-link></li>
                    <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
                        <router-link :to="breadcrumb.link">
                            {{ breadcrumb.text }}
                        </router-link>
                    </li>
                </ol>
                <slot name="header">
                    <h1 v-if="title != ''" class="page-header">{{ title }} <small v-if="subtitle && subtitle != ''">{{subtitle}}</small></h1>
                </slot>
            </div>
            <div class="ms-auto" v-if="!!this.$slots['header-right-buttons']">
                <slot name="header-right-buttons">

                </slot>
            </div>
        </div>

        <div class="mb-3 d-sm-flex fw-bold" v-if="!!this.$slots['header-actions']">
            <slot name="header-actions">

            </slot>
        </div>

        <slot></slot>
    </div>
</template>

<script>
import AppMenu from "@/config/AppMenu";
export default {
    name: "Page",
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            breadcrumbs: []
        }
    },
    methods: {
        findInMenu(path) {
            return AppMenu.find((item) => {
                return item.path == path
            })
        }
    },
    mounted() {
        let path = this.$route.path
        let aPathElements = path.trim().split('/');
        aPathElements.splice(0, 1);
        console.log('breadcrumbs', aPathElements)
        let link = '';
        aPathElements.forEach(pathElem => {

            if (pathElem !== '') {
                link += '/' + pathElem;
                const menuItem = this.findInMenu(link);
                this.breadcrumbs.push({
                    link: link,
                    text: menuItem ? menuItem.name : pathElem.charAt(0).toUpperCase() + pathElem.slice(1),
                })
            }
        })

    }
}
</script>

<style scoped>

</style>