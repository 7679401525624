export default function SecondsToTimeFilter(value, showSeconds = false) {

    let strRet = '';
    if (value) {
        let seconds = Math.abs(value);
        let h = Math.floor(seconds / 3600);
        let m = Math.floor((seconds - 3600 * h) / 60);
        let s = seconds - 3600 * h - 60 * m;
        if (h > 0) {
            strRet += h + ' godz. ';
        }
        if (m > 0) {
            strRet += m + ' min.';
        }
        if (showSeconds) {
            strRet += (strRet.length > 0 ? ' ' : '') + s + ' sek.'
        }
    }
    
    return strRet;
}
