import axios from 'axios';

const ImgSrcWithAuthDirective = {
    loadImage(el, binding, vnode) {
        if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
            ImgSrcWithAuthDirective.emit(vnode, 'image-loading', true);
            var imgSrc = binding.value;
            el.style.visibility = 'hidden';
            axios.get(imgSrc, {
                responseType: 'arraybuffer'
            }).then((response) => {
                var mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                el.src = 'data:' + mimeType + ';base64,' + imgBase64;
                //vnode.context.$emit('image-loaded');
                vnode.context.$nextTick( () => {
                    // ImgSrcWithAuthDirective.emit(vnode, 'image-loading', false);
                    // ImgSrcWithAuthDirective.emit(vnode, 'image-loaded', null)
                })

            }).catch((function () {
                el.src = imgSrc;
            }));
        }
    },
    emit(vnode, name, data)  {
        var handlers = (vnode.data && vnode.data.on) ||
            (vnode.componentOptions && vnode.componentOptions.listeners);

        if (handlers && handlers[name]) {
            handlers[name].fns(data);
        }
    },
    bind(el, binding, vnode) {
        console.log('bind');
        ImgSrcWithAuthDirective.loadImage(el, binding, vnode);
        el.onload = () => {
            console.log('el.onload');
            el.style.visibility = 'visible';
            ImgSrcWithAuthDirective.emit(vnode, 'image-loading', false);
            ImgSrcWithAuthDirective.emit(vnode, 'image-loaded', null)
        }
    },
    componentUpdated(el, binding, vnode) {
        console.log('componentUpdate');
        ImgSrcWithAuthDirective.loadImage(el, binding, vnode)
    }
}

export default ImgSrcWithAuthDirective;