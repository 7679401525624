import axios from 'axios';
import Vue from 'vue'
import store from '@/store/index'
import router from '@/config/AppRoutes'

axios.interceptors.request.use( (config) => {
    if (store.state.auth.isBussy && config.url.indexOf('/oauth/token') === -1) {
        console.log('request wait');
        setTimeout( () => {
            console.log('request go again');
            return Promise.resolve(config);
        }, 1000);
    } else {
        return config;
    }

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use( res => {
    if (res.headers['api-version'] && store.state.auth.api_version !== res.headers['api-version']) {
        store.commit(TYPES.AUTH_SET_API_VERSION, res.headers['api-version']);
    }
    return res;
}, error => {
    const status = error.response ? error.response.status : 0;
    if (status === 401) {
        Vue.notify({
            group: 'main',
            type: 'error',
            title: 'Nie jestes zalogowany',
            text: 'Musisz sie zalogowac'
        });
        router.replace('/login')
    } else if (status === 403) {
        Vue.notify({
            group: 'main',
            type: 'error',
            title: 'Brak uprawnień',
            text: 'Nie masz uprawnień'
        });
        router.replace('/')
    } else if (status === 422) {
        let message = '';
        Object.keys(error.response.data.errors).forEach( (key) => {
            const err = error.response.data.errors[key];
            err.forEach( (er) => {
                message +=  er + '<br/>';
            });
        })

        Vue.notify({
            group: 'main',
            type: 'error',
            title: 'Błędy w formularzu',
            text: message
        });
    } else {
        if (error.response && error.response.data && error.response.data.exception) {
            let exception =  error.response.data.exception
            let res = exception.match(/\\\w+$/g)
            if (res.length > 0) {
                exception = res[0];
            }
            exception = exception.replace('\\', '');
            Vue.notify({
                group: 'main',
                type: 'error',
                title: 'Błąd API: ' + status,
                text: error.response.statusText
            })
        } else {
            Vue.notify({
                group: 'main',
                type: 'error',
                title: 'Błąd API: ' + status,
                text: error.response ? error.response.statusText : ''
            })
        }

    }

    return Promise.reject(error);
})
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true;