<template>
    <div class="input-group with-focus">
        <input class="form-control h-45px fs-13px" :type="show ? 'text' : 'password'" :value="value" @input="$emit('input', $event)" :name="name"></input>
        <div class="input-group-append">
        <span class="input-group-text text-muted bg-transparent border-left-0">
            <em class="fas" :class="{'fa-eye' : !show, 'fa-eye-slash' : show}" @click="show = !show"></em>
        </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PasswordField",
        props: [
            'value',
            'name',
            'placeholder'
        ],
        data() {
            return {
                show: false
            }
        }
    }
</script>

<style scoped>

</style>
