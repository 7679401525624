<template>
    <div>
        <div class="text-center">
            <i class="fa fa-circle-notch fa-spin fa-3x"></i>
        </div>
        <div class="text text-center mt-3"><h4>Wczytywanie - proszę czekaj...</h4></div>
    </div>
</template>

<script>
  export default {
    name: 'Loading'
  }
</script>

<style scoped>

</style>
